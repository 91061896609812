import { useState } from 'react';
import './ModalSoldOut.scss';

const ModalSoldOut = () => {
  const [close, setClose] = useState(true);

  const onClose = () => {
    setClose(false);
  };
  return (
    <div className={`modal ${!close ? '-inactive' : ''}`}>
      <div className='modal__content'>
        <button onClick={() => onClose()} className='modal__button-close'>
          <svg
            width='17'
            height='19'
            viewBox='0 0 17 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.64355 12.3232L12.6462 19H16.7523L10.8691 9.82161L16.8213 0.850261H12.7152L8.79883 7.4235H8.72982L4.76172 0.850261H0.586589L6.48698 9.94238L0.500326 19H4.53743L8.55729 12.3232H8.64355Z'
              fill='white'
            />
          </svg>
        </button>
        <form
          method='POST'
          action='https://tribogaules.activehosted.com/proc.php'
          id='_form_13_'
          className='_form _form_13 _inline-form  _dark'
          noValidate
        >
          <input type='hidden' name='u' value='13' />
          <input type='hidden' name='f' value='13' />
          <input type='hidden' name='s' />
          <input type='hidden' name='c' value='0' />
          <input type='hidden' name='m' value='0' />
          <input type='hidden' name='act' value='sub' />
          <input type='hidden' name='v' value='2' />
          <input
            type='hidden'
            name='or'
            value='5d58eac29f0a5ee95bb79338e40a896c'
          />
          <div className='_form-content'>
            <div className='_form_element _x34220727 _full_width _clear'>
              <div className='_form-title'>Estoque esgotado</div>
            </div>
            <div className='_form_element _x46177054 _full_width _clear'>
              <div className='_html-code'>
                <p>
                  Deixe seu e-mail e avisaremos quando o produto estiver
                  novamente disponível.
                </p>
              </div>
            </div>
            <div className='_form_element _x34723545 _full_width '>
              <label htmlFor='firstname' className='_form-label'>
                Nome
              </label>
              <div className='_field-wrapper'>
                <input
                  type='text'
                  id='firstname'
                  name='firstname'
                  placeholder='Digite seu primeiro nome'
                  required={true}
                />
              </div>
            </div>
            <div className='_form_element _x77895433 _full_width '>
              <label htmlFor='email' className='_form-label'>
                Email*
              </label>
              <div className='_field-wrapper'>
                <input
                  type='text'
                  id='email'
                  name='email'
                  placeholder='Digite seu email'
                  required={true}
                />
              </div>
            </div>
            <div className='_button-wrapper _full_width'>
              <button id='_form_13_submit' className='_submit' type='submit'>
                AVISE-ME
              </button>
            </div>
            <div className='_clear-element'></div>
          </div>
          <div className='_form-thank-you' style={{ display: 'none' }}></div>
        </form>
      </div>
    </div>
  );
};

export default ModalSoldOut;
