import './SectionCollection.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'react-scroll';

const SectionCollection = ({ contentText }) => {
  const { title, description, buttonText } = contentText;
  return (
    <section className='section-collection'>
      <div className='container'>
        <div className='section-collection__arrow-down'>
          <Link to='collection' smooth={true} offset={-45}>
            <svg
              width='38'
              height='57'
              viewBox='0 0 38 57'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.0001 0C19.5305 0 20.0392 0.210714 20.4143 0.585786C20.7894 0.960859 21.0001 1.46957 21.0001 2V49.172L33.5841 36.584C33.9596 36.2085 34.469 35.9975 35.0001 35.9975C35.5312 35.9975 36.0405 36.2085 36.4161 36.584C36.7916 36.9595 37.0026 37.4689 37.0026 38C37.0026 38.5311 36.7916 39.0405 36.4161 39.416L20.4161 55.416C20.2303 55.6022 20.0096 55.75 19.7666 55.8508C19.5236 55.9517 19.2632 56.0036 19.0001 56.0036C18.737 56.0036 18.4765 55.9517 18.2336 55.8508C17.9906 55.75 17.7699 55.6022 17.5841 55.416L1.58408 39.416C1.20854 39.0405 0.997559 38.5311 0.997559 38C0.997559 37.4689 1.20854 36.9595 1.58408 36.584C1.95963 36.2085 2.46898 35.9975 3.00008 35.9975C3.53119 35.9975 4.04054 36.2085 4.41609 36.584L17.0001 49.172V2C17.0001 1.46957 17.2108 0.960859 17.5859 0.585786C17.9609 0.210714 18.4697 0 19.0001 0Z'
                fill='#181818'
              />
            </svg>
          </Link>
        </div>
        <div className='section-collection__banners'>
          <div className='section-collection__banners-banner'>
            <StaticImage src='../../images/destaque-1.png' alt='Destaque 1' />
          </div>
          <div className='section-collection__banners-banner'>
            <StaticImage src='../../images/destaque-2.png' alt='Destaque 2' />
          </div>
          <div className='section-collection__banners-banner'>
            <StaticImage src='../../images/destaque-3.png' alt='Destaque 3' />
          </div>
          <div className='section-collection__banners-banner section-collection__banners-image-bg'>
            <StaticImage src='../../images/destaque-4.png' alt='Destaque 4' />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='section-collection__description' id='collection'>
          <h3>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </h3>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          <a
            href='https://www.nike.com.br/tribo?utm_source=brand&utm_medium=lp&utm_campaign=tribo&utm_content=lp_tribo_cdp&orig_campaign=nikeifc'
            target='_blank'
            id='gtm_meet'
            className='section-collection__description-btn'
          >
            <span>
              <div dangerouslySetInnerHTML={{ __html: buttonText }} />
            </span>
            <svg
              width='18'
              height='16'
              viewBox='0 0 18 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.66675 7.99992H16.3334M9.91675 1.58325L16.3334 7.99992L9.91675 14.4166'
                stroke='#0084FF'
                strokeWidth='1.83333'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionCollection;
