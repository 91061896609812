import { StaticImage } from 'gatsby-plugin-image';
import Flags from '../Flags';
import useMediaQuery from 'hooks/useMediaQuery';
import { Link } from 'react-scroll';
import './Banner.scss';

const Banner = ({ lang }) => {
  const isMobile = useMediaQuery('mobile');
  return (
    <div className='banner'>
      {isMobile ? (
        <StaticImage
          src='../../images/banner-principal-mobile-v3.png'
          alt='Banner Principal Gaules'
        />
      ) : (
        <StaticImage
          src='../../images/banner-principal-v4.png'
          alt='Banner Principal Gaules'
        />
      )}

      <Flags lang={lang} />
      {isMobile && (
        <Link
          to='collection'
          className='banner__arrow-mob'
          smooth={true}
          offset={-45}
        >
          <svg
            width='30'
            height='46'
            viewBox='0 0 30 46'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M15.0001 0.24707C15.4311 0.24707 15.8444 0.41812 16.1491 0.722591C16.4539 1.02706 16.6251 1.44001 16.6251 1.8706V40.1632L26.8496 29.9447C27.1547 29.6398 27.5686 29.4685 28.0001 29.4685C28.4316 29.4685 28.8455 29.6398 29.1506 29.9447C29.4557 30.2495 29.6271 30.663 29.6272 31.0941C29.6272 31.5253 29.4557 31.9387 29.1506 32.2436L16.1506 45.2318C15.9997 45.383 15.8203 45.503 15.6229 45.5848C15.4255 45.6667 15.2138 45.7088 15.0001 45.7088C14.7864 45.7088 14.5747 45.6667 14.3773 45.5848C14.1799 45.503 14.0005 45.383 13.8496 45.2318L0.849599 32.2436C0.544468 31.9387 0.373047 31.5253 0.373047 31.0941C0.373047 30.663 0.544468 30.2495 0.849599 29.9447C1.15473 29.6398 1.56858 29.4685 2.0001 29.4685C2.43162 29.4685 2.84547 29.6398 3.1506 29.9447L13.3751 40.1632V1.8706C13.3751 1.44001 13.5463 1.02706 13.8511 0.722591C14.1558 0.41812 14.5691 0.24707 15.0001 0.24707Z'
              fill='white'
            />
          </svg>
        </Link>
      )}
    </div>
  );
};

export default Banner;
