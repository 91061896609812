import './SectionTribo.scss';

const SectionTribo = ({ contentText }) => {
  const { title, description, textButton } = contentText;
  return (
    <section className='section-tribo'>
      <div className='container'>
        <div className='section-tribo__column'>
          <h2>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </h2>
        </div>
        <div className='section-tribo__column'>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          <a
            href='https://www.nike.com.br/tribo?utm_source=brand&utm_medium=lp&utm_campaign=tribo&utm_content=lp_tribo_cdp&orig_campaign=nikeifc'
            target='_blank'
          >
            {textButton}
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionTribo;
