import { StaticImage } from 'gatsby-plugin-image';
import './Footer.scss';
const Footer = ({ contentText }) => {
  const { text, email } = contentText;
  return (
    <footer>
      <div>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <form
          method='POST'
          action='https://tribogaules.activehosted.com/proc.php'
          id='_form_11_'
          className='_form _form_11 _inline-form _inline-style _dark'
          noValidate
        >
          <input type='hidden' name='u' value='11' />
          <input type='hidden' name='f' value='11' />
          <input type='hidden' name='s' />
          <input type='hidden' name='c' value='0' />
          <input type='hidden' name='m' value='0' />
          <input type='hidden' name='act' value='sub' />
          <input type='hidden' name='v' value='2' />
          <input
            type='hidden'
            name='or'
            value='5bade7810647c24744ce9dd6f95524d8'
          />
          <div className='_form-content'>
            <div className='_form_element _x27385140 _inline-style'>
              <label htmlFor='email' className='_form-label'>
                {email.label}
              </label>
              <div className='_field-wrapper'>
                <input
                  type='text'
                  id='email'
                  name='email'
                  // placeholder='Digite seu email'
                  required
                />
              </div>
            </div>
            <div className='_button-wrapper _inline-style'>
              <button id='_form_11_submit' className='_submit' type='submit'>
                {email.send}
              </button>
            </div>
            <div className='_clear-element'></div>
          </div>
          <div className='_form-thank-you' style={{ display: 'none' }}>
            Obrigado por se cadastrar!
          </div>
        </form>
      </div>
      <div>
        <StaticImage src='../../images/gaules-footer.png' alt='Gaules' />
      </div>
    </footer>
  );
};

export default Footer;
