const parser = ({ table }) => {
  console.log(table);
  return table.rows
    .map((row) => [
      ...table.rows[0].c.map(
        (col, index) => index <= 3 && { [col.v]: row.c[index].v }
      ),
    ])
    .map((data) => data.reduce((acc, value) => ({ ...acc, ...value }), {}))
    .slice(1);
};

export default parser;
