import Banner from 'components/Banner';
import SectionTribo from 'components/SectionTribo';
import SectionCollection from 'components/SectionCollection';
import SectionVideo from 'components/SectionVideo';
import CarouselPosts from 'components/CarouselPosts';
import Footer from 'components/Footer';
import { useState, useEffect } from 'react';
import { SPREAD_SHEET_ID } from 'gatsby-env-variables';
import { parser } from 'utils';
import './AppLayout.scss';
import ModalSoldOut from '../../components/ModalSoldOut';

const AppLayout = ({ contentLang }) => {
  const [posts, setPosts] = useState([]);
  const urlGoogleShhet = `https://docs.google.com/spreadsheets/d/${SPREAD_SHEET_ID}/gviz/tq?tqx=out:json`;

  useEffect(() => {
    fetch(urlGoogleShhet)
      .then((res) => res.text())
      .then((text) => JSON.parse(text.substr(47).slice(0, -2)))
      .then((data) => {
        const parsePosts = parser(data);
        setPosts(parsePosts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {
    textSectionTribo,
    textSectionCollection,
    textSectionVideo,
    textCarouselPosts,
    textFooter,
    lang,
  } = contentLang;

  return (
    <>
      <Banner lang={lang} />
      <SectionTribo contentText={textSectionTribo} />
      <SectionCollection contentText={textSectionCollection} />
      <SectionVideo contentText={textSectionVideo} />
      <CarouselPosts posts={posts} contentText={textCarouselPosts} />
      <Footer contentText={textFooter} />
      <ModalSoldOut />
    </>
  );
};

export default AppLayout;
