import { useState } from 'react';
import './CarouselPosts.scss';

const CarouselPosts = ({ posts, contentText }) => {
  const { title, buttonText } = contentText;
  const [item, setItem] = useState(1);
  return (
    <section className='section-posts'>
      <div className='container'>
        <div className='section-posts__column'>
          <h3>
            <span>#</span>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </h3>
          <span id='gtm_post' className='section-posts__column-btn'>
            <div dangerouslySetInnerHTML={{ __html: buttonText }} />{' '}
            <svg
              width='21'
              height='21'
              viewBox='0 0 21 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.75 2.25L2.25 18.75M5 2.25H18.75V16'
                stroke='#181818'
                strokeWidth='2.75'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
        </div>
        <div className='section-posts__column section-posts__column--slide'>
          {posts.length > 0 && (
            <div className='section-posts__nav'>
              <button
                onClick={() =>
                  setItem((next) =>
                    next >= posts.length - 1 ? next - 2 : next + 2
                  )
                }
                disabled={posts.length - 1 <= item ? 'disabled' : ''}
              >
                <svg
                  width='37'
                  height='74'
                  viewBox='0 0 37 74'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='1.12567'
                    y='72.0428'
                    width='70.9169'
                    height='33.77'
                    rx='16.885'
                    transform='rotate(-90 1.12567 72.0428)'
                    stroke='#181818'
                    strokeWidth='2.25133'
                  />
                  <g clipPath='url(#clip0_209_1342)'>
                    <path
                      d='M10.9752 32.9259L18.0106 27.2976L25.046 32.9259M18.0106 27.2976L18.0106 46.9968'
                      stroke='#181818'
                      strokeWidth='2.67346'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_209_1342'>
                      <rect
                        width='22.5133'
                        height='22.5133'
                        fill='white'
                        transform='matrix(4.37114e-08 1 1 -4.37114e-08 6.75403 25.8904)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => setItem((prev) => (prev <= 1 ? 1 : prev - 2))}
                disabled={item == 1 ? 'disabled' : ''}
              >
                <svg
                  width='37'
                  height='74'
                  viewBox='0 0 37 74'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='34.8956'
                    y='1.80828'
                    width='70.9169'
                    height='33.77'
                    rx='16.885'
                    transform='rotate(90 34.8956 1.80828)'
                    stroke='#181818'
                    strokeWidth='2.25133'
                  />
                  <g clipPath='url(#clip0_209_1343)'>
                    <path
                      d='M25.046 40.9251L18.0106 46.5535L10.9752 40.9251M18.0106 46.5535L18.0106 26.8543'
                      stroke='#181818'
                      strokeWidth='2.67346'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_209_1343'>
                      <rect
                        width='22.5133'
                        height='22.5133'
                        fill='white'
                        transform='matrix(4.37114e-08 -1 -1 -4.37114e-08 29.2672 47.9604)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          )}
          <div className='section-posts__items'>
            {posts.length > 0 &&
              posts.map((post, index) => (
                <a
                  key={index}
                  className={`section-posts__item ${
                    index <= item ? '-is-active' : ''
                  }`}
                  href={post['Link do Post']}
                  target='_blank'
                  style={
                    index == 0
                      ? { marginTop: '0' }
                      : index == 1
                      ? { marginTop: '20px' }
                      : {}
                  }
                >
                  <img
                    src={post['Link da imagem']}
                    decoding='auto'
                    crossOrigin='anonymous'
                  />
                  <div>
                    <p>{post['Nome do Perfil']}</p>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselPosts;
