import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import './Flags.scss';

const Flags = ({ lang }) => {
  return (
    <>
      {lang == 'pt' ? (
        <Link className='button-flag' to='/en'>
          <StaticImage src='../../images/flag-br.png' alt='Português - BR' />
          Português - BR
        </Link>
      ) : (
        <Link className='button-flag' to='/'>
          <StaticImage src='../../images/flag-en.png' alt='English - US' />
          English - US
        </Link>
      )}
    </>
  );
};

export default Flags;
