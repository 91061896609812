import { StaticImage } from 'gatsby-plugin-image';
import './SectionVideo.scss';
import { useState } from 'react';
// import ModalVideo from 'react-modal-video';
import YouTube from 'react-youtube';

const Play = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M32 0C14.328 0 0 14.328 0 32C0 49.672 14.328 64 32 64C49.672 64 64 49.672 64 32C64 14.328 49.672 0 32 0ZM32 7.42187C45.5733 7.42187 56.5781 18.4267 56.5781 32C56.5781 45.5733 45.5733 56.5781 32 56.5781C18.4267 56.5781 7.42187 45.5733 7.42187 32C7.42187 18.4267 18.4267 7.42187 32 7.42187ZM24 16.0234V47.9765L48 32L24 16.0234Z'
      fill='white'
    />
  </svg>
);

const SectionVideo = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenVideoTwo, setOpenVideoTwo] = useState(false);

  const videoOptions = {
    playerVars: {
      height: 'auto',
      width: '100%',
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 0,
      loop: 0,
    },
  };

  return (
    <>
      <section className='section-video'>
        <div className='container'>
          <div className='section-video__content'>
            {isOpen && (
              <YouTube
                opts={videoOptions}
                videoId={'d38kHknf-7o'}
                className={`section-video__video ${
                  isOpen ? 'active-video' : ''
                }`}
              />
            )}
            <>
              <StaticImage
                src='../../images/bg-video.png'
                alt='Video Background'
                className={`${isOpen ? 'active-video' : ''}`}
              />
              <button
                onClick={() => setOpen(true)}
                className={`${isOpen ? 'active-video' : ''}`}
              >
                <Play />
              </button>
            </>
          </div>
        </div>
        <div className='section-video__banners'>
          <div>
            <StaticImage
              src='../../images/tribo-1.png'
              alt='Tribo Destaque 1'
            />
          </div>

          <div>
            <StaticImage
              src='../../images/tribo-2.png'
              alt='Tribo Destaque 2'
            />
          </div>

          <div>
            <StaticImage
              src='../../images/tribo-3.png'
              alt='Tribo Destaque 3'
            />
          </div>

          <div>
            <StaticImage
              src='../../images/tribo-4.png'
              alt='Tribo Destaque 4'
            />
          </div>
        </div>
        <div className='container section-video__making-of'>
          <div className='section-video__content'>
            {isOpenVideoTwo && (
              <YouTube
                opts={videoOptions}
                videoId={'d38kHknf-7o'}
                className={`section-video__video ${
                  isOpenVideoTwo ? 'active-video' : ''
                }`}
              />
            )}
            <>
              <StaticImage
                src='../../images/bg-video-2.png'
                alt='Video Background'
                className={`${isOpenVideoTwo ? 'active-video' : ''}`}
              />
              <button
                onClick={() => setOpenVideoTwo(true)}
                className={`${isOpenVideoTwo ? 'active-video' : ''}`}
              >
                <Play />
              </button>
            </>
          </div>
          <div>
            <h3>
              Making <br />
              of
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionVideo;
