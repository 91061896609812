import { useEffect, useState } from 'react';
import isClient from 'utils/isClient';

const useMediaQuery = (size) => {
  const [matches, setMatches] = useState();

  const sizes = {
    mobile: '(max-width: 700px)',
    tablet: '(max-width: 1024px)',
    desktop: '(min-width: 1025px)',
  };

  const query = sizes[size];

  useEffect(() => {
    if (isClient()) {
      const onResize = () => setMatches(window.matchMedia(query).matches);
      setMatches(window.matchMedia(query).matches);
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, []);

  return matches;
};

export default useMediaQuery;
